import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Col, Container, Row, InputGroup, FormControl, Button} from "react-bootstrap"
import campaign from '../images/sharing.svg'
import proposals from '../images/digital-marketing.svg'
import monitoring from '../images/monitoring.svg'
import collaborate from '../images/social-engagement.svg'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      influencer: file(relativePath: { eq: "inlfue.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      findInfluencers: file(relativePath: { eq: "FindInfluencers.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      proposals: file(relativePath: { eq: "Proposals.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      collaborate: file(relativePath: { eq: "Collaborate.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Buzzvert - Influencer marketing accessible to brands of all sizes"/>
      <section className="bg-gradient">
        <Container className="py-5">
          <Row className="align-items-center">
            <Col xs="12" sm="6" className="order-1 order-md-0">
              <h1>Find the perfect <i className="font-weight-bolder">influencer</i> for your brand</h1>
              <p>
                Unlock the power of Influencer Marketing. Our community of influencers allows you
                to start promoting your brand in minutes.
              </p>
              <div>
                <form action="/influencers" method="GET">
                  <InputGroup className="my-4" size="lg">
                    <FormControl
                      id="keyword"
                      name="keyword"
                      placeholder='Try "Skincare"'
                      aria-label='Try "Skincare"'
                      aria-describedby="find-influencers"
                    />
                    <InputGroup.Append>
                      <Button type="submit" variant="primary">Find influencer</Button>
                    </InputGroup.Append>
                  </InputGroup>
                </form>
              </div>
              <div className="d-flex">
                <span>Popular:</span>&nbsp;
                <ul className="list-unstyled list-inline font-weight-bold">
                  <li className="list-inline-item"><Link to="/influencers">Fashion</Link></li>
                  <li className="list-inline-item"><Link to="/influencers">Beauty</Link></li>
                  <li className="list-inline-item"><Link to="/influencers">Fitness</Link></li>
                  <li className="list-inline-item"><Link to="/influencers">Food</Link></li>
                </ul>
              </div>
            </Col>
            <Col className="d-flex justify-content-center order-0 order-md-1 mb-5 mb-md-0" xs="12" sm="6">
              <Img className="w-75" fluid={data?.influencer?.childImageSharp?.fluid}/>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-light">
        <Container className="py-5">
          <Row>
            <Col>
              <h2 id="how-it-works">How does it work?</h2>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col>
              <div className="text-center"><img src={proposals} width="100" alt="Post a campaign" /></div>
              <h4 className="text-center mt-3">Post a campaign</h4>
              <p>
	              Use our quick and easy form to describe your brand and product.
                The more detail you can give, the more relevant influencers you’ll attract.
              </p>
            </Col>
            <Col>
              <div className="text-center"><img src={campaign} width="100" alt="Receive Proposals" /></div>
              <h4 className="text-center mt-3">Receive Proposals</h4>
              <p>
                Our AI system does the hard work — matching and contacting the best influencers for your brand.
	              Each influencer then responds with their tailored proposal within minutes.
              </p>
            </Col>
            <Col>
              <div className="text-center"><img src={collaborate} width="100" alt="Collaborate" /></div>
              <h4 className="text-center mt-3">Collaborate</h4>
              <p>
                Communicate ideas, receive files and share feedback all from the same space.
                You can set your terms, approve influencers posts and make payments.
              </p>
            </Col>
            <Col>
              <div className="text-center"><img src={monitoring} width="100" alt="Measure Results" /></div>
              <h4 className="text-center mt-3">Measure Results</h4>
              <p>
                Be first to know when influencer posts go live and if they match campaign guidelines.
                Track campaign engagements and measure influencer performance.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="py-5">
          <Row>
            <Col>
              <h2 id="features">Features</h2>
            </Col>
          </Row>
          <Row className="align-items-center mb-5">
            <Col xs="12" md="6" className="order-1 order-md-0">
              <h3 className="mb-3">Discover <b className="text-primary">trusted</b> creators</h3>
              <p>Search for <b>rated and reviewed</b> creators in every niche imaginable.</p>
              <p>
                We check everybody before allowing them onto our platform to prevent fraud.
              </p>
              <p>
                Refine your search by specific keyword, <b>engagement rate</b>, language, country, and <b>audience demographics</b>.
              </p>
              <Button as={Link} className="mt-3" to="/influencers">Browse Influencers</Button>
            </Col>
            <Col xs="12" md="6" className="d-flex justify-content-center order-0 order-md-1">
              <Img style={{ width: '300px'}} className="my-3 my-md-0" fluid={data?.findInfluencers?.childImageSharp?.fluid}/>
            </Col>
          </Row>
          <Row className="align-items-center mb-5">
            <Col xs="12" md="6" className="d-flex justify-content-center">
              <Img style={{ width: '300px'}} className="my-3 my-md-0"  fluid={data?.proposals?.childImageSharp?.fluid}/>
            </Col>
            <Col xs="12" md="6">
              <h3 className="mb-3">Receive <b className="text-primary">tailored</b> proposals</h3>
              <p>
                Our <b>AI system</b> does the hard work — matching and contacting the best influencers for your campaign.
              </p>
              <p>
                Each influencer then responds with their <b>tailored proposal within minutes</b>.
              </p>
              <Button as={Link} className="mt-3" to="/influencers">Try For Free</Button>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs="12" md="6" className="order-1 order-md-0">
              <h3 className="mb-3">Stay <b className="text-primary">in control</b>, keep in touch</h3>
              <p><b>Communicate</b> ideas, receive files and <b>share feedback</b> all from the same space.</p>
              <p>
                You can set your terms, <b>approve</b> influencers posts and <b>make payments</b>.
              </p>
              <p>
                Stay in control with our <b>streamlined communication</b> and simple, organised <b>invoicing</b>.
              </p>
              <Button as={Link} className="mt-3" to="/influencers">Get Started</Button>
            </Col>
            <Col xs="12" md="6" className="d-flex justify-content-center order-0 order-md-1">
              <Img style={{ width: '300px'}} className="my-3 my-md-0"  fluid={data?.collaborate?.childImageSharp?.fluid}/>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
